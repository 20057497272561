import $ from 'jquery';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import Bg from './_bg';
import { watchViewport, unwatchViewport, getViewportState } from 'tornis';
export default class Home {
  constructor() {
    this.bg;
    this.init();
  }
  init() {
    console.log('//Home');
    this.setAnimation();
    this.setBg();

    $(window).on('resize', () => {
      this.onResize();
    });
    $(window).on('scroll', () => {
      this.onScroll();
    });
    this.setPosition();
  }
  setAnimation() {
    //hero
    gsap.fromTo(
      $('.bg'),
      { opacity: 0.8 },
      {
        opacity: 0.5,
        scrollTrigger: {
          trigger: $('.hero'),
          start: 'bottom bottom',
          end: 'bottom center',
          scrub: true, // スクラブアニメーション　数値で慣性を制御
          ease: 'none',
          invalidateOnRefresh: true,
          // markers: true,
        },
      }
    );

    gsap.utils.toArray('.anime-slidein').forEach((target) => {
      ScrollTrigger.create({
        trigger: target,
        start: 'top 90%',
        end: 'bottom top',
        toggleClass: {
          targets: target,
          className: 'is-active',
          invalidateOnRefresh: true,
        },
        // markers: true,
      });
    });

    gsap.utils.toArray('.hero .anime').forEach((target) => {
      ScrollTrigger.create({
        trigger: target,
        start: 'top bottom',
        end: 'top top',
        toggleClass: {
          targets: target,
          className: 'is-active',
          invalidateOnRefresh: true,
        },
        // markers: true,
      });
    });
    //見出し
    gsap.utils.toArray('.section-header.anime').forEach((target) => {
      ScrollTrigger.create({
        trigger: target,
        start: 'top 90%',
        end: 'bottom top',
        toggleClass: {
          targets: target,
          className: 'is-active',
          invalidateOnRefresh: true,
        },
        // markers: true,
      });
    });
    //service の羽
    gsap.utils.toArray('.service .item').forEach((target) => {
      gsap.fromTo(
        target,
        { x: () => '100%', opacity: 0 },
        {
          x: () => '0%',
          opacity: 1,
          scrollTrigger: {
            trigger: target,
            start: 'top bottom',
            end: 'top center',
            scrub: true, // スクラブアニメーション　数値で慣性を制御
            ease: 'none',
            invalidateOnRefresh: true,
            // markers: true,
          },
        }
      );
    });
  }
  setBg() {
    let mm = gsap.matchMedia();
    let cameraY;
    mm.add('(min-width: 769px)', () => {
      // desktop setup code here...
      cameraY = -2;
    });

    mm.add('(max-width: 768px)', () => {
      // mobile setup code here...
      cameraY = -3;
    });
    this.bg = new Bg();
    // define a watched function, to be run on each update
    const updateValues = ({
      size,
      scroll,
      mouse,
      position,
      orientation,
      devicePixelRatio,
    }) => {
      if (scroll.changed) {
        this.bg.setOptions = { cameraY: cameraY + scroll.top * -0.02 };
      }
    };

    // bind the watch function
    watchViewport(updateValues);

    // to get a snapshot of the current viewport state
    const state = getViewportState();
  }
  setPosition() {
    let st = $(window).scrollTop();
    let dh = $(document).innerHeight();
    let wh = $(window).outerHeight();
    if (st <= 10) {
      $('.scroll-attention').addClass('show');
    } else {
      $('.scroll-attention').removeClass('show');
    }
  }
  onScroll() {
    this.setPosition();
  }
  onResize() {
    this.setPosition();
    // ScrollTrigger.refresh();
  }
}
