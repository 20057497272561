import WebFont from 'webfontloader';
import $ from 'jquery';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Lenis from 'lenis';
import Home from './_home';
class Hane {
  constructor() {
    this.$loader = $('#loader');
    this.$btnTop = $('.pagetop');
    this.init();
  }
  init() {
    console.log('//Hane');
    window.lenis = new Lenis({
      smoothTouch: false,
      lerp: 0.08, // 慣性の強さ
      duration: 1.2, // スクロールアニメーションの時間
      // easing: easeOutQuart, // イージング関数
    });
    window.lenis.on('scroll', ScrollTrigger.update);
    gsap.registerPlugin(ScrollTrigger);
    gsap.ticker.add((time) => {
      window.lenis.raf(time * 1000);
    });
    gsap.ticker.lagSmoothing(0);

    Promise.resolve()
      .then(() => {
        console.log('/////// Load Start ///////');
        let p = new Promise((resolve, reject) => {
          resolve();
        });
        return p;
      })
      .then(() => {
        //フォントの読み込みと画像の読み込みをパラレルで実行
        return Promise.all([this.loadFont()]);
      })
      .then(() => {
        console.log('/////// Load end ///////');
        gsap.to(this.$loader, 1, {
          opacity: 0,
          display: 'none',
        });

        if ($('html').hasClass('home')) {
          new Home();
        }
      });

    $(window).on('resize', () => {
      this.onResize();
    });
    $(window).on('scroll', () => {
      this.onScroll();
    });
    this.setPosition();
  }
  onResize() {
    this.setPosition();
  }
  onScroll() {
    this.setPosition();
  }
  loadFont() {
    console.log('/////// Load Font ///////');
    return new Promise((resolve, reject) => {
      WebFont.load({
        // custom: { families: [ 'Noto Sans Japanese',],
        // 	urls:['https://fonts.googleapis.com/earlyaccess/notosansjapanese.css']},
        google: {
          families: ['Noto Sans JP:500'],
        },
        loading: function () {
          // ロードが開始されたとき
          console.log('loading web font!!');
        },
        active: function () {
          // Web Fontが使用可能になったとき
          console.log('active all web font!!');
          resolve();
        },
        inactive: function () {
          // ブラウザがサポートしていないとき
          console.log('inactive web font!!');
        },
        fontloading: function (fontFamily, fontDescription) {
          // fontFamilyのロードが開始されたとき
          //console.log('fontloading web font!!');
        },
        fontactive: function (fontFamily, fontDescription) {
          // fontFamilyが使用可能になったとき
          console.log('active web font!!');
        },
        fontinactive: function (fontFamily, fontDescription) {
          // fontFamilyをブラウザがサポートしていないとき
          console.log('inactive web font!!');
        },
      });
    });
  }
  setPosition() {
    /**
     * スクロールバーのサイズを取得
     */
    let scrollBarWidth = window.innerWidth - document.body.clientWidth + 'px';
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      scrollBarWidth
    );
  }
}
// jQuery Ready
$(function () {
  let acc = new Hane();
});
