import $ from 'jquery';
import './_vender/vanta.clouds';
export default class Bg {
  constructor() {
    this.clouds;
    this.init();
  }
  init() {
    console.log('//Bg');

    this.clouds = VANTA.CLOUDS({
      el: '.bg',
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      // minHeight: 200.0,
      cameraY: 0,
      skyColor: 0x1a96d5,
      cloudColor: 0x9ccee7,
      cloudShadowColor: 0x062e5a,
      sunColor: 0xffffff, // 0x1a9eaa
      sunGlareColor: 0x9ddcff,
      sunlightColor: 0x9ddcff, // 0x1a9eaa
      speed: 1,
    });
    $(window).on('resize', () => {
      this.onResize();
    });
    $(window).on('scroll', () => {
      this.onScroll();
    });
    this.setPosition();
  }
  set setOptions(obj) {
    // console.log(obj);
    this.clouds.setOptions(obj);
  }
  setPosition() {}
  onScroll() {
    this.setPosition();
  }
  onResize() {
    this.setPosition();
    // ScrollTrigger.refresh();
  }
}
